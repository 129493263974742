// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin-bottom: 50px;

  font-family: 'JetBrains Mono', -apple-system, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:"JetBrains Mono", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
font{
  font-family: "JetBrains Mono", ui-monospace;
  font-weight: bolder;
  font-size: medium;

}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;;EAEnB;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;AACA;EACE,2CAA2C;EAC3C,mBAAmB;EACnB,iBAAiB;;AAEnB","sourcesContent":["body {\n  margin-bottom: 50px;\n\n  font-family: 'JetBrains Mono', -apple-system, 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family:\"JetBrains Mono\", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\nfont{\n  font-family: \"JetBrains Mono\", ui-monospace;\n  font-weight: bolder;\n  font-size: medium;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
