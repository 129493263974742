// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background-color: #ffffff;
}

.App-logo {
  height: 10vmin;

}





.App-header {


  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #141415;

}

.App-link {
  font-size: calc(6px + 1vmin);
  color: #181818;


}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;;AAEhB;;;;;;AAMA;;;EAGE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;EAEvB,cAAc;;AAEhB;;AAEA;EACE,4BAA4B;EAC5B,cAAc;;;AAGhB","sourcesContent":[".App {\n  text-align: center;\n  background-color: #ffffff;\n}\n\n.App-logo {\n  height: 10vmin;\n\n}\n\n\n\n\n\n.App-header {\n\n\n  min-height: 80vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  color: #141415;\n\n}\n\n.App-link {\n  font-size: calc(6px + 1vmin);\n  color: #181818;\n\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
