import './App.css';
import {useState} from "react";

function CXMEmailLink({email="studio@contextmacine.ru",rel="opener referrer",target="_blank"}) {
    return <a className="App-link"
              href={`mailto:${email}`}
              target={target}
              rel={rel}>
              {email}
    </a>
}
function CXMLogo({logo1 = 'logo-dot.png',logo2 = 'logo-solid.png',...props}) {
    const [logoSrc, setLogoSrc]=useState(logo1);
    const handlePointerOut=()=>{
        setLogoSrc(logo1)
}
    const handlePointerOver=()=>{
        setLogoSrc(logo2)
    }

    return <>
            <img onPointerOver={handlePointerOver}
                 onPointerOut={handlePointerOut }
                 src={logoSrc}
                 className="App-logo" alt="logo" {...props}/>
            </>
}
function App() {

  return (
    <div className="App">
      <header className="App-header">
          <CXMLogo logo1={'logo-dot.png'} logo2={'logo-solid.png'}/>
      </header>
        <p>
            <CXMEmailLink email={"studio@contextmacine.ru"}/>
       </p>
    </div>
  );
}

export default App;
